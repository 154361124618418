 html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none!important;
    color: #0B093B;
  }
  a:hover {
    color: #7620FF;
  }
  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #fff inset; /* Change the color to your own background color */
    box-shadow: transparent;
    -webkit-text-fill-color: #fff;
  }
  body {
    background: #fff;
    color: #0B093B;
    font-family: 'Khula', sans-serif;
    font-display: swap;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
  }
  .container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 30px;
  }
  .w-100 {
    width: 100%;
  }
  .uppercase {	
    text-transform: uppercase;
  }

  @media only screen and (max-width: 859px) {
    .container {
      padding: 0 15px;
    }
  }
  .regular {
    font-weight: 400!important;
  }
  .semiBold {
    font-weight: 600!important;
  }
  .extraBold {
    font-weight: 800!important;
  }

  /* HELLPERS */
  .textCenter {
    text-align: center
  }
  .animate {
    transition: 0.3s ease;
  }
  .pointer {
    cursor: pointer;
  }
  .relative {
    position: relative;
  }
  .textCenter {
    text-align: center;
  }
  .textLeft {
    text-align: left;
  }
  .textRight {
    text-align: right;
  }
  .x100 {
    width: 100%;
  }
  .active {
    border-bottom: 2px solid #7620FF;
    color: #7620FF;
  }
  .tag {
    padding: 5px 10px;
    margin: 10px 0;
  }
  .shadow {
    box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
  }
  /* FLEXBOX */
  .flex {
    display: flex;
  }
  .flexSpaceCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexNullCenter {
    display: flex;
    align-items: center;
  }
  .flexColumn {
    flex-direction: column;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flexSpaceNull {
    display: flex;
    justify-content: space-between;
  }
  .contentEnd {
    justify-content: end;
  }

  /* FONT SIZES */
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.75rem;
  }
  .font13 {
    font-size: 0.813rem;
  }
  .font14 {
    font-size: 0.875rem;
  }
  .font15 {
    font-size: 0.938rem;
  }
  .font18 {
    font-size: 1.125rem;
  }
  .font20 {
    font-size: 1.25rem;
  }
  .font25 {
    font-size: 1.563rem;
  }
  .font30 {
    font-size: 1.875rem;
  }
  .font40 {
    font-size: 2.5rem;
  }
  .font60 {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
  @media only screen and (max-width: 859px) {
    .font11 {
      font-size: 0.688rem;
    }
    .font12 {
      font-size: 0.688rem;
    }
    .font13 {
      font-size: 0.75rem;
    }
    .font14 {
      font-size: 0.813rem;
    }
    .font15 {
      font-size: 0.813rem;
    }
    .font18 {
      font-size: 0.938rem;
    }
    .font20 {
      font-size: 1.125rem;
    }
    .font25 {
      font-size: 1.25rem;
    }
    .font30 {
      font-size: 1.5rem;
    }
    .font40 {
      font-size: 1.875rem;
    }
    .font60 {
      font-size: 2.8rem;
    }
  }
  /* BORDER RADIUS */
  .radius8 {
    border-radius: 1rem;
  }
  .radius6 {
    border-radius: 0.6rem;
  }
  /* COLORS */
  .darkColor {
    color: #0B093B;
  }
  .purpleColor {
    color: #7620FF;
  }
  .orangeColor {
    color: #F2B300;
  }
  .lightColor {
    color: #F5F5F5;
  }
  .coralColor {
    color: #C4FFFF;
  }
  .greenColor {
    color: #49CB86;
  }
  .greyColor {
    color: #707070;
  }
  .whiteColor {
    color: #fff;
  }
  /* BACKGROUNDS */
  .darkBg {
    background-color: #0B093B;
  }
  .purpleBg {
    background-color: #7620FF;
  }
  .orangeBg {
    background-color: #F2B300;
  }
  .lightBg {
    background-color: #F5F5F5;
  }
  .coralBg {
    background-color: #C4FFFF;
  }
  .greenBg {
    background-color: #49CB86;
  }
  .greyBg {
    background-color: #707070;
  }
  .whiteBg {
    background-color: #fff;
  }

/* custom css */
.fix-height {
  max-height: 309px;
  object-fit: cover;
  object-position: top;
}

.slick-prev:before, .slick-next:before {
  font-size: 25px;
  color: black;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-7 {
  margin-bottom: 2rem;
}

/* grid */
.d-grid {
  display: grid;
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

@media only screen and (max-width: 768px) {
  .grid-xs-4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-xs-3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-sm-2 {
    grid-template-columns: repeat(1, 1fr);
  }
  
}

@media only screen and (max-width: 500px) {
  .grid-xs-4 {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-xs-3 {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-xs-2 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .d-sm-none {
    display: none;
  }
}


.textBlack {
  color: black;
}


/* testimonialSlider */
.testimonialSlider .slick-track
{
    display: flex !important;
}

.testimonialSlider .slick-slide
{
    height: inherit !important;
    display: flex;
    margin-top: 30px;
}

/* scope */
.wave-steps {
  padding: 88px 0;
}
.steps {
  position: relative;
  width: 100%;
  max-width: 629px;
  margin: 0 auto;
}
.step {
  border-radius: 10px;
  font-size: 10px;
  width: 100px;
  height: 100px;
}

.step .text {
  transform: rotate(45deg);
  text-align: center;
  position: relative;
  left: 5px;
  top: 24px;
  font-size: 11px;
  color: white;
  font-weight: bold;
}

.step:nth-child(even) .text {
  transform: rotate(135deg);
  top: 36px;
}

.step .text .step-number {
  font-size: 25px;
  line-height: 22px;
}

.step:nth-child(odd) {
  border-bottom-left-radius: 30px;
  transform: rotate(-45deg);
  background-color: #7620ff;
  position: absolute;
  bottom: 0;
}

.step:nth-child(even) {
  background: red;
  border-bottom-right-radius: 30px;
  transform: rotate(-135deg);
  position: absolute;
  top: -74px;
}

.step:first-child {
  position: unset;
}

.step2 {
  position: absolute;
  top: -74px;
  left: 74px;
}

.step3 {
  position: absolute;
  bottom: 0;
  left: 148px;
}

.step4 {
  position: absolute;
  top: -74px;
  left: 74px;
}

.step5 {
  position: absolute;
  bottom: 0;
  bottom: 296px;
}

.service-text {
  max-width: 70%;
}

.blur-gb {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
}

@media only screen and (max-width: 980px) {
  .service-text {
    max-width: 100%;
  }
}

@media only screen and (max-width: 860px) {
  .blur-gb {
    display: none;
  }
}

input:-webkit-autofill {
  background-color: transparent;
  -webkit-text-fill-color: black;
}

.khulaFont {
  font-family: "Khula",sans-serif !important;
  font-display: swap;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}